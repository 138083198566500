.sidebar {
  text-align: center;
  position: fixed;
  left: 0;
  z-index: 999;
  overflow: hidden;
  width: 0rem;
  height: 98%;
  /* height: 100vh; */
  border-radius: 2rem;
  transition: all 0.5s;
  margin-left:-50rem;
  
}
.sidebar.company{
  background-color: var(--companyTheme);
  color: #ffffff;

}
.sidebar.agent{
  background-color: var(--staffTheme);
  color:#ffffff;
  font-weight: 600 !important;
  font-size: 0.9375rem !important;
  margin-left: 0rem !important;
  border-radius: 0rem;
  height: 100%;
}

.sidebar-logo-img{
  width: 55%;
  
  /* Only for FOXA */
  -webkit-filter: invert(90%); /* Safari 6.0 - 9.0 */
  filter: invert(100%)
}

.sidebar-header{
  font-weight: 600;
}

.sidebar.open.agent{
  width: 17.5rem;
}
.sidebar.open.company{
  width: 20rem;
}
.active-company{
  color: #3E66CD!important;
}

.sidebar-items li{
  padding: 1rem 1rem .2rem 1rem;
}

.sidebar-items li.agent{
  padding: 0.62rem 0.44rem 0.62rem 1rem;
}

.sidebar-items li.agent.active-agent{
  border-radius: 0.3125rem;
  background: #FFF;  
}

.sidebar-items li.active-agent a{
  color: #393939;
}

.sidebar-items li a{
  font-weight: 600;
  text-decoration: none;
  color: #ffffff;
}
.sidebar-items li a{
  font-weight: 600;
  text-decoration: none;
  color: #ffffff;
}

.sidebar-agent-logo{
  background-color:   #D9D9D9;
  /* width: 100px; */
  height: 100%;
  border-radius: 50%;
  object-fit: fill;
}
.sidebar-agent-links{
  width: 50px;
  color: #ffffff;
  border: 1px solid;
  border-radius: 1rem;
  padding: 0.9rem 0.9rem !important;
}

.staff-toggle{
  /* top:0; */
  right:0;
}

.link-popup{
  background-color: #686b6f;
}