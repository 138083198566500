#searchLead{
    font-size: smaller;
}
#leadsearch{
    /* display: flex; */
    align-items: end;
    /* justify-content: end; */
}

.lead-searchBox{
    border-radius: 2rem;
    box-shadow: 1px 1px 3px #ababab;
}

.lead-add-btn{
    position: fixed;
    right: 0;
}

.lead-add-btn.mobile-view{
    bottom: 0;
    margin-bottom: 1rem;
}


.lead-card{
    border-radius: .8rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px #818181;
    transition: all .1s;
    height: 4.8rem;
}
.lead-card:hover{
 transform: scale(1.004);
}

.lead-title-card{
    border-radius: .8rem;
    background-color: var(--companyTable);
    box-shadow: 0px 2px 8px #818181;
    transition: all .1s;
    height: 4.8rem;
}

.lead-title-card.agent{
    background-color: var(--staffTable) !important;
}

.lead-card-title{
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    /* line-height: 10px; */
    color: #000000;
    letter-spacing: 0.035em;
}
.lead-card-head{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    /* line-height: 30px; */
    color: #ffffff;
    letter-spacing: 0.035em;
}

.serial-number{
    line-height: 15px;
}

.lead-data{
    margin-bottom: .4rem;
    /* position: relative; */
    /* color: #000000; */
}

.lead-content{
    margin-right: 1rem;
}
.assign-lead{
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    /* line-height: 30px; */
    letter-spacing: 0.025em; 
}

.assign-lead {
    color: #32f302;
}

.assign-lead .Unassigned{
    color: #ffba0a;
}

.lead-status{
    border-radius: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    /* line-height: 18px; */
    color: #ffffff;
    letter-spacing: 0.035em;
}
.lead-status-container .Closed{
    background-color: #32f302;
    
}
.lead-status-container .Open{
    background-color: #25b4d3;
}
.lead-status-container .Rejected{
    background-color: #d31c1c;
}
.lead-status-container .Unavailable{
    background-color: #dec910;
}
.lead-status-container .Paused{
    background-color: #5437f7c7;
}
.lead-status-container .New{
    color: #32f302;
    border: 1px solid #32f302;
}

.lead-container{
    border-radius: 1rem;
    /* height: 400px; */
    min-height:10px;
    max-height: 900px;
    overflow-x: hidden;
    overflow-y: auto;
}

.lead-plus-button{
    width: 64px;
    height: 64px;
}

.lead-modal{
    border-radius: 4rem;
}
.lead-desc-modal{
    border-radius: 2rem;
    background-color: #F4F4F4;
}
.lead-desc-area{
    border-radius: 1.5rem;
    text-decoration: none;
    resize: none;
    height: 15rem;
    border: none;
}

.next-page {
    font-style: bold;
    font-weight: 1000;
    font-size: 17px;
    color: #ffffff;
    letter-spacing: 0.025em;
}

.lead-dropdown {
    position: absolute;
    z-index: 9999;
}

.status-dropdown{
    width: 6.5rem !important;
    padding-left: 0.2rem;
    font-weight: 400    ;
    padding-right: 0;
    font-size: 11px;
}

.status-dropdown.field{
    width: 100% !important;
    padding: 5px;
    font-size: 14px;
}
.status-dropdown.field>option{
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.055em;
}
.status-dropdown .menu{
    /* font-family: 'Poppins', sans-serif !important; */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
}

.source-dropdown{
    /* width: 6.5rem !important; */
    padding-left: 1rem;
    font-weight: 400    ;
    padding-right: 0;
    font-size: 12px;
}
.source-dropdown .menu{
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
    padding-left: 1rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.025em;
}

.lead-datepicker .MuiInputBase-root{
    height: 3rem;
    /* background-color: red; */
}
  
.field-card{
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
}

@media only screen and (max-width: 768px){
    .profile-edit-card{
        right: 3rem !important;
    }

    
}

.col-md-1.clear-filter{
    width: 5%!important;
}

.profile-edit-card{
    right: 4rem;
}

.filter-container{
    width: 12%!important;
}

.search-trigger{
    background-color: #ffffff!important;
    color: #121212!important;
    /* width: 100%; */
    aspect-ratio: 1/1!important;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 0px 5px rgb(151, 149, 149);

}

.search-bar {
    position: relative;
    z-index: -1;
    left:200px;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease-in,z-index 0s ease-in;
  }
  
  .search-bar.show {
    left:0;
    z-index: 0;
    visibility: visible;
    opacity: 1;
    transition: all 500ms ease-in,z-index 1s ease-in;
  }
.lead-upload-dropdown{
    padding-left: 1rem !important;
    font-weight: 400;
    font-size: 12px;
    border-radius: 2rem !important;
    /* height: auto; */
    /* overflow: scroll; */
}

.lead-upload-dropdown .label{
    padding: auto;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 2rem !important;
}

.lead-upload-dropdown.ui.upward.selection.dropdown.visible{
    font-size: 12px !important;
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
}

/* .lead-upload-dropdown.ui.upward.selection.dropdown.visible{
    font-size: 12px;
    border-bottom-right-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
} */

/* .lead-upload-dropdown .menu{
    width: 50% !important;
} */

.name1{
    color: red;
    font-size: 10px;
    letter-spacing: 0.01rem;
    padding: 0;
}